<template>
  <div v-for="value in values" :key="value.id" class="flex flex-col">
    <div v-if="value.type === gridType">
      <GridElement
          v-if="value.pivot.status === 'accepted'"
          :value="value"
          :loading-option="loadingOption"
          :collaboration-id="collaborationId"
          :collaboration-status="collaborationStatus"
          :collaboration-foodies="collaborationFoodies"
          :foodies-meeting="foodiesMeeting"
          @mark-assistance="markAssistance"/>
    </div>
  </div>
  <div v-for="value in values" :key="value.id" class="flex flex-col">
    <div v-if="value.type === gridType">
      <GridElement
          v-if="value.pivot.status !== 'accepted'"
          :value="value"
          :loading-option="loadingOption"
          :collaboration-id="collaborationId"
          :collaboration-status="collaborationStatus"
          :collaboration-foodies="collaborationFoodies"
          :foodies-meeting="foodiesMeeting"
          @mark-assistance="markAssistance"/>
    </div>
  </div>
</template>

<script>
// import Restaurant from "@/views/public/register/Restaurant";
import GridElement from '@/components/authenticated/collaboration/GridSimpleElement'

export default {
  name: 'GridSimple',
  props: {
    values: Object,
    gridType: String,
    collaborationId: Number,
    loadingOption: Boolean,
    collaborationStatus: String,
    collaborationFoodies: Object,
    foodiesMeeting: Boolean
  },
  emits: ['markAssistance'],
  components: {
    GridElement
  },
  methods: {
    markAssistance (id) {
      this.$emit('markAssistance', id)
    }
  }
}
</script>

<style scoped>

</style>
