<template>
  <transition name="fade">
    <div v-show="foodies.length > 0"  class="flex px-normal mb-sm transform">
      <div v-for="foodie in foodies" :key="foodie" class="mr-2">
        <a-initials :src="foodie.profile_picture" :name="foodie.name" :desing-class="'h-10 w-10'"/>
      </div>
    </div>
  </transition>

  <div class="mx-normal border text-lg border-gray-300 text-gray rounded-full px-2 py-1 flex items-center my-2">
    <i class="icon-search flex-none pr-2 font-bold" />
    <input
        ref="search"
        autofocus
        v-debounce:[400]="search => fetch( { search: search, page: 1 })"
        placeholder="Buscar Foodies"
        class="flex-auto bg-opacity-75 bg-white text-black focus:outline-none w-full">
    <div class="flex-none">
      <a-loader class="h-4 w-4" v-if="loading"/>
    </div>
  </div>

  <a-list
      class="flex flex-col pb-32 px-normal"
      :source="pagination.data"
      :loading="loading"
      :should-emit-bottom-reached="pagination.next_page_url !== null"
      @bottom-reached="fetch({ page: pagination.next_page_url ? pagination.current_page + 1 : pagination.current_page }, true)">
    <template #default="{ item }">
      <div
          v-if="!addedFoodies.includes(item.id)"
          class="flex items-center text-left pb-normal grid-element-padding card_border_gray">
        <div class="flex-none grid-thumb-image flex-initial align-middle left-0">
          <a-initials :name="item?.name" :src="item?.profile_picture" :desing-class="'avatar_size'"></a-initials>
        </div>

        <div class="flex w-full">
          <div class="flex-auto">
            <b class="text-black" style="word-break: break-all;"><i :class="item.favorite ? 'icon-heart text-red text-lg' : 'icon-heartEmpty'" class="align-middle pr-1"/>{{ item.ig_username ? '@' + item.ig_username : item.name }}</b>
            <div class="">
              <div class="flex-auto pl-small">
                <p class="fz-10" >{{ `${ item.ig_followers_count ? item.ig_followers_count : 0 } followers` }}</p>
                <p class="fz-10" >{{ `${ item.ig_engagement_prc }% engagement` }}</p>
                <p class="fz-10" >{{ `${ item.completed_collabs_count ? item.completed_collabs_count : '0' } colaboraciones` }}</p>
                <Rating :modelValue="(item.rating || 0)" :stars="5" :cancel="false" class="" :readonly="true"/>
              </div>
            </div>
          </div>

          <div class="flex-none self-end">
            <div class="text-right">
              <button
                  @click="checkFoodie(item)"
                  :class="foodieList.includes(item.id) ? 'bg-white text-primary custom-text-color active:bg-primary' : 'bg-primary text-white custom-text-color-reverse active:bg-white'"
                  class="valorating_btn rounded-full mt-4 border border-primary custom-text-color">
                {{ foodieList.includes(item.id) ? 'Añadido' : 'Añadir' }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template #empty>
      <div class="border rounded-lg flex items-center text-left py-1">
        <div class="flex-none">
          <img src="../../../assets/emptyuser.png"/>
        </div>
        <div class="flex-auto">
          <h2 class="font-bold">No se han encontrado foodies</h2>
        </div>
      </div>
    </template>
  </a-list>
  <div class="fixed flex h-10 bottom-16 w-full px-normal custom-width">
    <button @click="$emit('cancelAddFoodies', false)" class="flex-1 custom-shadow custom-gray mr-1 text-white rounded-full lg:w-4/5 lg:mx-2">Cancelar</button>
    <button @click="$emit('confirmAddFoodies', foodies)"
            :disabled="foodies.length < 1"
            :class="foodies.length < 1 ? 'custom-disabled' : 'bg-primary text-white'"
            class="flex-1 custom-shadow ml-1 text-white rounded-full lg:w-4/5 lg:mx-2">Aceptar</button>
  </div>

</template>

<script>
import Rating from 'primevue/rating'

export default {
  name: 'collaborationAddFoodies',
  props: {
    selectedFoodies: { required: false },
    foodiesLength: { required: false },
    minIg: { required: false }
  },
  emits: ['cancelAddFoodies', 'confirmAddFoodies', 'update:foodiesLength'],
  components: {
    Rating
  },
  data () {
    return {
      pagination: [],
      foodieList: [],
      foodies: [],
      addedFoodies: [],
      loading: false,
      addedFoodie: false,
      previewFoodies: [],
      query: {
        limit: 15,
        // sort_by: 'created_at,desc',
        explore: 1,
        with_count: 'completedCollabs'
      },
      modal: {
        create: false
      }
    }
  },
  methods: {
    checkFoodie (foodie) {
      const find = this.foodieList.includes(foodie.id)

      if (find) {
        const pos = this.foodieList.indexOf(foodie.id)

        this.foodieList.splice(pos, 1)
        this.foodies.splice(pos, 1)
      } else {
        this.foodieList.push(foodie.id)
        this.foodies.push(foodie)
      }
      this.$emit('update:foodiesLength', this.foodies.length)
    },
    fetch (segment = {}, appending = false) {
      this.loading = !this.loading
      const query = { ...this.query, ...segment, ig_followers_count: this.minIg.min_ig_followers ? '>' + this.minIg.min_ig_followers : '' }
      this.$repository.foodies
        .index(query)
        .then(({ data: pagination }) => {
          this.pagination = appending
            ? { ...pagination, data: [...this.pagination.data, ...pagination.data] }
            : pagination

          this.query = query
        })
        .finally(() => (this.loading = !this.loading))
    }
  },
  mounted () {
    this.fetch()
    this.addedFoodies = this.selectedFoodies.map(item => item.id)
    this.foodieList = []
    this.foodies = []
    this.$emit('update:foodiesLength', 0)
  }
}
</script>

<style scoped>
.custom-disabled{
  background-color: #aaaaaa;
  color: #ffffff;
}
.custom-shadow{
  box-shadow: 0 2px 6px 0 #605858;
}
.custom-gray{
  background-color: #aaaaaa;
}
.custom-width{
  max-width: 1024px;
}
.custom-text-color{
  color: #397EF3;
}
.custom-text-color-reverse{
  color: #ffffff;
}
.custom-text-color:active{
  color: #ffffff;
}
.custom-text-color-reverse:active{
  color: #397EF3;
}

</style>
