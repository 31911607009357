<template>
  <div v-if="loading" class="custom-loading pt-16">
    <a-loader color="gray_color h-1 w-1"/>
  </div>
  <div v-else>
    <Header :values="values" :header="header"></Header>
    <div class="p-normal pt-16">
      <div class="flex border-gray border rounded-md p-sm">
        <div class="flex-auto text-left">
          <div>
            <div :class="`donut ${values.uses > (foodies.length * 0.8) ? `completed_donut` : ``}`" :style="`--first:${1-(values.uses/(foodies.length > 0 ? foodies.length : 1))}; --second: ${values.uses/(foodies.length > 0 ? foodies.length : 1)}`">
              <div class="donut__slice donut__slice__first"></div>
              <div :class="`donut__slice ${ values.uses > (foodies.length * 0.8) ? `donut__slice__completed` : `donut__slice__second`}`"></div>
              <div class="donut__label">
                <i v-if="values.foodies_meeting" class="icon-users"></i>
                <i v-else class="icon-user"></i>
                <p v-if="values.type === 'public'">{{ `${values.uses}/${values.uses_limit}` }}</p>
                <p v-else>{{ `${values.uses}/${foodies.length}` }}</p>
              </div>
            </div>

            <div class="inline_txt">
              <div v-if="values.foodies_meeting" class="inline_label"><div class="label_quedada l_q_yellow">Foodie quedada</div></div>
              <div v-if="values.type !== 'private'" class="inline_label"><div class="label_quedada l_q_green">pública</div></div>
              <div v-html="getDate"></div>
<!--              <p v-if="values.allowed_week_days.length > 6" class="font-regular" style="line-height: 1.1;">Todos los días</p>-->
<!--              <p v-else-if="values.allowed_week_days.length !== 1" class="font-regular" style="line-height: 1.1;">Lunes a jueves</p>-->
              <div class="font-regular grid grid-cols-7 items-center text-center" style="max-width: 150px; gap: 1rem;">
                <div v-for="day in days" :key="day" class="justify-center items-center">
                  <div
                      class="flex items-center justify-center days-grid"
                      style="width: 18px; height: 18px;"
                      :class="{'bg-onboarding_light_gray text-white rounded-full': values?.allowed_week_days?.includes(day.id)}">
                    <p class="leading-none">
                      {{ day.label }}
                    </p>
                  </div>
                </div>
              </div>
              <p v-if="!values.foodies_meeting" class="font-regular">
                <i class="icon-user"></i>
                +{{ values.companions_limit }} acompañantes
              </p>
              <!--p class="font-light">Fecha de creación:</p>
              <p class="font-light gray_color">{{ getCreationDate }}</p-->
            </div>
          </div>
        </div>
        <div class="flex-none flex flex-col justify-between">
          <div v-if="values.is_premium" class="flex items-center ml-auto justify-center bg-yellow_ribbon rounded-full h-6 w-6">
            <i class="pi pi-star" style="font-size: 12px"></i>
          </div>
          <b v-if="values.discount_type === 'prc'" class="text-xl text-black">{{ `${values.discount_value}%` }}</b>
          <b v-if="values.discount_type === 'fixed_e2'" class="text-xl text-black">{{ `${(values.discount_value / 100)}€` }}</b>
          <b v-if="values.is_premium && !loadingFoodies" class="text-xl text-black">{{ '+' + getPrice + '€' }}</b>
        </div>
      </div>
      <!--    <div v-if="user.isRestaurant" class="flex text-left">-->
    </div>

    <div class="mx-normal text-left border border-gray rounded-md p-sm" v-if="values.requirements">
      <h2 class="text-xl text-black">Requerimientos</h2>
      <p>{{ values.requirements  }}</p>
    </div>

    <div v-if="values.is_premium" class="grid grid-col-1 gap-1 mt-2 mx-4">
      <div v-for="product in products" :key="product">
        <div class="flex border border-gray text-onboarding_gray p-2 rounded-md text-left">
          <p class="flex-grow">{{ product.description }}</p>
          <h3 class="font-bold text-right text-lg text-onboarding_gray">{{ $filters.currencyFormatter(product.price_e2 / 100) + '€ + IVA' }}</h3>
        </div>
      </div>
    </div>

    <div v-if="values.is_premium" class="text-left p-normal pb-2">
      <b class="text-xl text-black">Invitado</b>
    </div>
    <div v-else class="text-left" :class="addFoodies ? 'p-normal pb-0' : 'p-normal'">
      <b v-if="values.type === 'private'" class="text-xl text-black">{{ addFoodies ? `Añadir foodies ${foodiesLength ? '(' + foodiesLength + ')' : ''}`: 'Invitados'}}</b>
      <b v-else class="text-xl text-black">Solicitantes</b>
      <span v-if="values.type === 'private' && !addFoodies && values.status !== 'closed' && values.status !== 'canceled'" @click="addFoodies = !addFoodies" class="text-primary float-right cursor-pointer">+ Añadir</span>
    </div>

     <div>
        <div v-if="values.type === 'public' && values.status !== 'canceled'" class="p-normal pt-0">
          <div v-if="values.foodies.length === 0" class="border border-gray rounded-md p-sm no_foodies">
            <div class="inline_img">
              <img src="@/assets/emptyuser.png" alt="default" data-v-4ea357f6="" style="width: 75px; height: 75px;">
            </div>
            <div class="inline_txt" style="padding-right: 75px">
              Todavía no tienes solicitudes
            </div>
          </div>
          <div v-else>
            <Grid
              :values="foodies"
              :loading-option="loadingOption"
              :collaboration-id="values.id"
              :collaboration-status="values.status"
              :collaboration-foodies="values"
              :foodies-meeting="values.foodies_meeting"
              @mark-assistance="getProfileValues()"
            />
          </div>
        </div>
        <div v-else-if="values.uses > 0 || values.type === 'private'">
          <div v-if="!addFoodies" class="px-normal" :class="values.status !== 'closed' ? 'pb-4' : 'pb-16'">
            <a-loader v-if="loadingFoodies" class="mx-auto block w-10 h-10" :color="'text-gray'"/>
            <Grid
              v-else
              :values="foodies"
              :loading-option="loadingOption"
              :collaboration-id="values.id"
              :collaboration-status="values.status"
              :collaboration-foodies="values"
              :foodies-meeting="values.foodies_meeting"
              @mark-assistance="getProfileValues()"
            />
          </div>
          <div v-else>
            <CollaborationAddFoodies
            :selected-foodies="foodies"
            :min-ig="values"
            v-model:foodies-length="foodiesLength"
            @confirm-add-foodies="confirmAdd"
            @cancel-add-foodies="addFoodies = $event"/>
          </div>
        </div>
     </div>

    <div v-if="values.status !== 'closed'">
<!--      <div v-if="values.uses === 0 && values.status !== 'canceled' || values.status !== 'ignored' && !addFoodies || values.status !== 'canceled'" class="block bg-white">-->
<!--        <button @click="cancel = !cancel" class="gray_color h-base pt-">-->
<!--          Quiero cancelar la colaboración-->
<!--        </button>-->
<!--      </div>-->
      <div v-if="values.status !== 'canceled'" class="block bg-white pb-16">
        <button @click="cancel = !cancel" class="gray_color h-base pt-">
          Quiero cancelar la colaboración
        </button>
      </div>
    </div>
  </div>

  <a-alert :show="cancel" @confirm="cancelColab" @cancel="cancel = !cancel">
    <div class="text-left space-y-4">
      <h1 class="text-lg font-semibold text-red">Cancelar colaboración - <span class="text-primary">{{ values.code }}</span></h1>
      <p>¿Está seguro que desea cancelar esta colaboración?</p>
    </div>
  </a-alert>
  <a-alert hide-cancel :show="cancelError" @confirm="cancelError = !cancelError">
    <div class="text-left space-y-4">
      <h1 class="text-lg font-semibold text-red">No se puede cancelar <span class="text-primary">{{ values.code }}</span></h1>
      <p>La colaboración ya ha sido aceptada por un foodie o ya no se encuentra disponible</p>
    </div>
  </a-alert>

  <a-alert
      :show="modal.add"
      @cancel="modal.add = false"
      class="text-center">
    <div class="text-center">
      <h1 class="text-xl font-bold text-black pt-2">¿Estás seguro?</h1>
      <p class="mt-1 text-black">No podrás cancelarles una vez invitados</p>
      <div class="border rounded-md mt-2 mb-4">
        <div v-for="foodie in foodiesToAdd" :key="foodie" class="border-b flex p-2">
          <a-initials :src="foodie.profile_picture" :name="foodie.name" :desing-class="'h-10 w-10'"/>
          <div class="text-left pl-2">
            <h2 class="font-bold text-black">{{ foodie.ig_username ? '@' + foodie.ig_username : foodie.name }}</h2>
            <h2 class="text-sm">{{ foodie?.ig_followers_count }} seguidores</h2>
          </div>
          <!--        <a-initials :src="foodie.profile_picture" :name="foodie.name" :desing-class="'h-3 w-3'" />-->
        </div>
      </div>
    </div>
    <template #footer>
      <div class="flex w-full text-center pb-2">
        <div class="flex-1 px-small">
          <button @click="modal.add = false" class="custom-gray w-32 h-8 text-white rounded-full">
            Cancelar
          </button>
        </div>
        <div class="flex-1 px-small">
          <button @click="addFoodiesRequest" class="bg-primary w-32 h-8 text-white rounded-full">
            Confirmar
          </button>
        </div>
      </div>
    </template>
  </a-alert>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import Header from '@/components/authenticated/Header'
import 'moment/locale/es'
import Grid from '@/components/authenticated/collaboration/GridSimple'
import CollaborationAddFoodies from './collaborationAddFoodies'

export default {
  name: 'CollaborationShow',
  components: {
    CollaborationAddFoodies,
    Header,
    Grid
  },
  data () {
    return {
      values: [],
      foodies: [],
      foodiesLength: 0,
      query: {
        with: 'foodies'
      },
      header: {
        title: 'COLAB DEL',
        type: 'collaboration.show',
        public: false
      },
      days: [
        { label: 'L', id: 1 },
        { label: 'M', id: 2 },
        { label: 'M', id: 3 },
        { label: 'J', id: 4 },
        { label: 'V', id: 5 },
        { label: 'S', id: 6 },
        { label: 'D', id: 7 }
      ],
      discountValue: '',
      months: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre'
      ],
      foodiesToAdd: [],
      confirmAddFoodies: false,
      addFoodies: false,
      loading: true,
      loadingOption: false,
      loadingFoodies: false,
      cancel: false,
      cancelError: false,
      completed: true,
      modal: {
        add: false
      },
      products: []
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'session/isAuthenticated'
    }),
    ...mapState({
      user: ({ session }) => session.user
    }),
    getPrice () {
      let price = 0
      this.products.map(product => {
        price += parseFloat(product.price_e2)
      })
      return this.$filters.currencyFormatter(price / 100)
    },
    getDate () {
      if (this.values.start_at !== this.values.end_at) {
        return `Del ${this.$filters.moment(this.values.start_at, 'DD/MM/YY', true)} al ${this.$filters.moment(this.values.end_at, 'DD/MM/YY', true)}`
      } else {
        if (!this.values.foodies_meeting) {
          return `Del ${this.$filters.moment(this.values.start_at, 'DD/MM/YY', true)} al ${this.$filters.moment(this.values.end_at, 'DD/MM/YY', true)}`
        } else {
          return `<p style="text-transform: capitalize;">
        ${this.$filters.moment(this.values.end_at, 'dddd DD/MM/YY', true)} </br>
        ${this.$filters.moment(this.values.end_at, 'HH:mm', true)}h
        </p>`
        }
      }
    },
    getCreationDate () {
      return new Date(this.values.start_at).getDate() + ' ' +
        this.months[new Date(this.values.start_at).getMonth()] + ' ' +
        new Date(this.values.start_at).getFullYear()
    }
  },
  methods: {
    ...mapActions({
      restaurantColab: 'collaborations/show'
    }),
    getRestaurantColab (id) {
      this.loadingFoodies = !this.loadingFoodies
      this.restaurantColab({ id: id, values: { with: 'foodies' } })
        .then(response => {
          this.values = response
          this.values.allowed_week_days = response.allowed_week_days.map(day => (parseInt(day)))
          this.$repository.collaborations
            .withFoodies(id)
            .then(({ data: response }) => {
              this.foodies = response
              if (this.values.is_premium) {
                this.products = response[0].pivot.products
              }
            })
            .catch((error) => (console.log(error)))
            .finally(() => (this.loadingFoodies = !this.loadingFoodies))
        })
        .finally(() => (this.loading = false))
    },
    getProfileValues () {
      if (this.user.isRestaurant) {
        this.getRestaurantColab(this.$route.params.id)
      }
    },
    getDays () {
      return 'válido toda la semana'
    },
    cancelColab () {
      this.$repository.collaborations
        .cancel(this.values.id)
        .then(() => {
          this.cancel = !this.cancel
          this.$router.push({ path: '/collaboration' })
        })
        .catch(() => {
          this.cancel = !this.cancel
          this.cancelError = !this.cancelError
        })
    },
    markAssitance (id) {
      this.loadingOption = !this.loadingOption
      this.$repository.collaborations.collaboration(this.values.id)
        .markAssisted(id)
        .catch(error => (console.log(error)))
        .finally(this.loading.status = !this.loading.status)
    },
    confirmAdd (foodies) {
      this.modal.add = true
      this.foodiesToAdd = foodies
    },
    addFoodiesRequest () {
      const toAdd = this.foodiesToAdd.map(item => item.id)
      this.$repository.collaborations
        .addFoodies(this.$route.params.id, { foodies: toAdd })
        .then((response) => (console.log(response)))
        .catch((response) => (console.log(response)))
        .finally(() => {
          this.modal.add = false
          this.$router.go()
        })
    }
  },
  created () {
    this.getProfileValues()
  }
}
</script>

<style lang="scss" scoped>
.custom-gray{
  background-color: #aaaaaa;
}
.gcp-restaurant{
  padding: 0 15px 115px 15px;
}
.inline_discount{
  display: inline-block;
  vertical-align: top;
}

.no_foodies{
  .inline_img{
    display: inline-block;
    width: 75px;
    vertical-align: middle;
  }
  .inline_txt{
    width: calc(100% - 75px);
    padding: 0;
    color: #AAA;
    font-size: 14px;
    font-weight: 500;
  }
}

</style>
