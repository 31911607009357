<template>
  <component :is="`collaboration-show-${user.isFoodie ? 'foodies' : 'restaurants'}`"/>
</template>

<script>
import { mapState } from 'vuex'
import CollaborationShowFoodies from '@/views/authenticated/foodies/collaboration/show'
import CollaborationShowRestaurants from '@/views/authenticated/restaurants/CollaborationShow'

export default {
  components: {
    CollaborationShowFoodies,
    CollaborationShowRestaurants
  },
  computed: mapState({
    user: ({ session }) => session.user
  })
}
</script>
