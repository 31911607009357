<template>
  <div :class="`flex flex-col text-left pb-normal grid-element-padding card_border_gray ${value.pivot.status === `canceled` ? `gray_color` : `text-dgray`}`">
    <div class="flex items-center">
      <div class="flex-auto flex cursor-pointer">
        <div @click="handleRedirect" class="flex-none grid-thumb-image flex-initial align-middle left-0 avatar_size self-center">
          <a-initials :name="value?.name" :src="value?.profile_picture" :desing-class="'avatar_size'"></a-initials>
          <div id="foodieImage" class="text-white text-xl text-left avatar-inset">
            <i v-if="value?.pivot?.status === 'pending'" class="icon-menu2 rounded-full bg-gray-400 h-6 w-6 p-small "></i>
            <i v-if="value?.pivot?.status === 'canceled'" class="icon-close rounded-full bg-red h-6 w-6 p-small"></i>
            <!--          <i v-if="value?.pivot?.status === 'expired'" class="icon-close rounded-full bg-red h-6 w-6 p-small"></i>-->
            <i v-if="value?.pivot?.status === 'ignored'" class="icon-close rounded-full bg-gray-400 h-6 w-6 p-small"></i>
            <i v-if="value?.pivot?.status === 'accepted'" class="icon-checkmark rounded-full bg-primary h-6 w-6 p-small"></i>
            <i v-if="value?.pivot?.status === 'completed' && value?.pivot?.restaurant_rated && !value?.pivot?.foodie_rated" class="icon-thumbsUp rounded-full bg-primary h-6 w-6 p-small"></i>
            <i v-if="value?.pivot?.status === 'completed' && value?.pivot?.restaurant_rated && value?.pivot?.foodie_rated" class="icon-colaborate rounded-full bg-green-400 h-6 w-6 p-small"></i>
          </div>
        </div>
        <div @click="handleRedirect"  class="flex-auto flex pl-small overflow-hidden">
          <div class="flex flex-col">
            <b class="text-black truncate custom-width" :title="value.ig_username ? '@' + value.ig_username : value.name">{{ value.ig_username ? '@' + value.ig_username : value.name }}</b>
            <b>{{ value.name }}</b>
            <!--          <b class="text-black"><i :class="value.favorite ? 'icon-heart text-red text-lg' : 'icon-heartEmpty'" class="align-middle pr-1"/>{{ value.ig_username ? '@' + value.ig_username : value.name }}</b>-->
            <!--          <p class="fz-10" >{{ `${value.ig_followers_count} Followers` }}</p>-->
            <!--          <p class="fz-10" >{{ `${value.ig_engagement_prc}% Engagement` }}</p>-->
            <!--          <p class="fz-10" >{{ `${value.completed_collabs_count ? value.completed_collabs_count : '0'} Colaboraciones` }}</p>-->
            <Rating :modelValue="(value.rating || 0)" :stars="5" :cancel="false" :readonly="true" class="flex items-center mt-1"/>
            <!--          <p class="font-bold" v-if="!foodiesMeeting">{{ value.collab_booking_datetime ? `Reserva: ${$filters.moment(value?.collab_booking_datetime, 'DD-MM-YYYY HH:mm')}` : 'Reserva: En espera'}}</p>-->
            <div v-if="!foodiesMeeting" class="mt-1 text-sm">
              <i v-if="value.pivot.reservation_code && (value?.pivot?.status !== 'pending')" class="gray_color inline-block">{{ `#${value.pivot.reservation_code}` }}</i>
              <p class="font-bold inline-block text-primary ml-1" v-if="value.collab_booking_datetime">{{ $filters.moment(value?.collab_booking_datetime, 'DD/MM/YY-HH:mm') }}</p>
            </div>
          </div>
          <div class="flex-none">
            <Tag :state="value.state" />
          </div>
        </div>
        <div class="flex flex-col justify-between min-width">
          <!--        <div class="text-right pivot_satus">-->
          <div v-if="value.is_premium" class="flex items-center ml-auto justify-center bg-yellow_ribbon rounded-full h-6 w-6">
            <i class="pi pi-star" style="font-size: 12px"></i>
          </div>
<!--          <i v-if="value.pivot.reservation_code && (value?.pivot?.status !== 'pending')" class="gray_color">{{ `#${value.pivot.reservation_code}` }}</i>-->
          <template v-if="value.pivot.status === 'pending_confirmation'">
            <button @click="confirmIgnored = true" class="valorating_btn rounded-full gray_color font-bold gray_color">
              Ignorar
            </button>
            <button
                @click="confirmFoodie = true"
                :class="`valorating_btn rounded-full bg-primary mt-4 white_color`"
                :disabled="collaborationStatus === 'canceled' || collaborationStatus === 'completed'">
              Aceptar
            </button>
          </template>
          <template v-else>
            <p v-if="value.pivot.status === 'pending'" class="gray_color font-bold">Esperando</p>
            <p v-if="value.pivot.status === 'canceled'" class="text-red font-bold">Cancelado</p>
            <p v-if="value.pivot.status === 'expired'" class="text-red font-bold">Ignorado</p>
            <p v-if="value.pivot.status === 'completed'" class="text-green-400 font-bold">Completado</p>
            <p v-if="value.pivot.status === 'ignored'" class="text-red font-bold">Declinado</p>
            <p v-if="value.pivot.status === 'accepted' && !value?.pivot.restaurant_rated" class="text-primary font-bold">Aceptado</p>
          </template>
          <template v-if="hasPassedGraceDays || value?.pivot?.restaurant_rated">
            <p @click="rate = true"
               v-if="value.pivot.restaurant_rated && !value.pivot.foodie_rated"
               class="valorating_btn rounded-full bg-primary mt-4 text-white">
              Valorar
            </p>
            <p v-if="value.pivot.restaurant_rated && value.pivot.foodie_rated"
               class="text-green-400 mt-4">
              Valorado
            </p>
          </template>
        </div>
      </div>
<!--      </div>-->
    </div>
    <div class="flex items-center text-center border-t mt-2 pt-2">
      <div class="flex-1 flex flex-col">
        <h4 class="text-onboarding_gray font-bold">{{ followersFormatter(value.ig_followers_count)}}</h4>
        <h4>Followers</h4>
      </div>
      <div class="flex-1 flex flex-col">
        <h4 class="text-onboarding_gray font-bold">{{ value.ig_engagement_prc + '%'}}</h4>
        <h4>Engagement</h4>
      </div>
      <div class="flex-1 flex flex-col">
        <h4 class="text-onboarding_gray font-bold">{{ value.completed_collabs_count ? value.completed_collabs_count : '0' }}</h4>
        <h4>Colabs</h4>
      </div>
    </div>
  </div>

  <a-alert :show="rate" @cancel="rate = false"
           @confirm="submitRate"
           :confirm-text="'Enviar'"
           :cancel-text="'Cancelar'"
           title="Valorar Foodie"
    >
    <Rating v-model="rating.rate" :stars="5" :cancel="false" class="text-center w-full mt-5 mx-auto"/>
    <textarea v-model="rating.comment" placeholder="Comentario (opcional)" class="mt-normal w-full p-2 border rounded-md" rows="5"></textarea>
    <div class="text-center mx-auto mt-2">
      <div class="space-x-2 text-sm">
        <p>
          <!--            Aceptar los <router-link class="text-primary">términos y condiciones</router-link>-->
          Al hacer una reseña aceptas que SoloFoodies trate tus datos según nuestra Política de Privacidad. Los recogemos para facilitar la publicación, gestión y control de las opiniones de los usuarios. No compartiremos tus datos con terceros, salvo obligación legal. Puedes ejercer tus derechos de acceso, rectificación, supresión y oposición, entre otros, según nuestra Política de Privacidad.
        </p>
      </div>
    </div>
  </a-alert>

  <a-alert hide-cancel :show="confirm"
           @confirm="reload"
           :confirm-text="'Aceptar'"
           :title="'Enviada con éxito'"
    >
    <div class="flex">
      <i class="icon-checkmark-circle-2-outline text-green-400 inline-block text-xxl pr-2 align-middle"></i>
      <p class="inline-block align-middle">¡Gracias! Tu valoración ayuda a todos los que formamos parte de esta comunidad</p>
    </div>
  </a-alert>

  <a-alert
    :show="confirmFoodie"
    @confirm="acceptFoodie"
    @cancel="confirmFoodie = false"
    :loading="loading.status"
    :confirm-text="'Aceptar'"
    :title="'Aceptar foodie'"
  >
    <div class="flex">
      <p class="inline-block align-middle">¿Desea agregar a <span class="text-dgray font-bold">{{ value.ig_username ? '@' + value.ig_username : value.name }}</span> a la colaboración?</p>
    </div>
  </a-alert>

  <a-alert
    hide-cancel
    :show="errorMessage !== null"
    @confirm="errorMessage = null"
    :confirm-text="'Aceptar'"
    :title="'Error'"
  >
    <div class="flex">
      <p class="inline-block align-middle text-red">{{ errorMessage }}</p>
    </div>
  </a-alert>

  <a-alert
    :show="confirmIgnored"
    @confirm="ignoreFoodie"
    @cancel="confirmIgnored = false"
    :confirm-text="'Aceptar'"
    :title="'Ignorar foodie'"
  >
    <div class="flex">
      <p class="inline-block align-middle">¿Desea ignorar a <span class="text-dgray font-bold">{{ value.ig_username ? '@' + value.ig_username : value.name }}</span>?</p>
    </div>
  </a-alert>
</template>
<script>

import { mapGetters, mapState } from 'vuex'
import Rating from 'primevue/rating'
import moment from 'moment'
import Tag from '../../Tag'

export default {
  name: 'GridElement',
  props: {
    value: Object,
    loadingOption: Boolean,
    collaborationId: Number,
    collaborationStatus: String,
    collaborationFoodies: Object,
    foodiesMeeting: Boolean
  },
  components: {
    Tag,
    Rating
  },
  data () {
    return {
      code: '',
      body: {
        attach: [],
        detach: []
      },
      loading: {
        status: false
      },
      rating: {
        rate: 5,
        comment: ''
      },
      rate: false,
      confirm: false,
      confirmFoodie: false,
      confirmIgnored: false,
      terms: false,
      errorMessage: null
    }
  },
  emits: ['markAssistance'],
  computed: {
    ...mapGetters({
      isAuthenticated: 'session/isAuthenticated'
    }),
    ...mapState({
      user: ({ session }) => session.user
    }),
    endLoad () {
      return this.loadingOption ? !this.loading.status : this.loadingOption
    },
    hasPassedGraceDays () {
      const endDate = moment(this.collaborationFoodies.end_at).add(10, 'days')
      return moment(endDate).isAfter(this.collaborationFoodies.end_at)
    }
  },
  methods: {
    followersFormatter (followers) {
      if (followers >= 1000000) {
        return parseFloat(followers / 1000000).toFixed(1) + 'M'
      } else if (followers >= 1100) {
        return parseFloat(followers / 1000).toFixed(1) + 'k'
      }
      return followers
    },
    handleRedirect () {
      this.$router.push({ path: `/explore/${this.value.id}` })
    },
    markAssisted () {
      this.$emit('markAssistance', this.value.id)
    },
    // markIgnored () {
    //   this.$repository.collaborations
    //     .markIgnored()
    //     .then(() => ())
    //     .catch((error) => ())
    // }
    submitRate () {
      this.$repository.collaborations
        .collaboration(this.collaborationId)
        .rateFoodie(this.value.id, { rate: this.rating.rate, comment: this.rating.comment })
        .catch(error => (console.log(error)))
        .finally(() => {
          this.rate = !this.rate
          this.confirm = !this.confirm
          this.$router.go()
        })
    },
    acceptFoodie () {
      this.loading.status = true
      this.$repository.collaborations
        .collaboration(this.collaborationId)
        .handShake(this.value.id)
        .then(() => {
          this.confirmFoodie = false
          this.markAssisted()
        })
        .catch(({ response }) => {
          this.confirmFoodie = false
          switch (response.data.error_code) {
            case 'EC010': {
              this.errorMessage = 'El foodie ha alcanzado el límite de colaboraciones'
              break
            }
          }
        })
        .finally(() => {
          this.loading.status = false
          this.confirmFoodie = false
        })
    },
    ignoreFoodie () {
      this.$repository.collaborations
        .foodie(this.value.id)
        .setIgnored(this.collaborationId)
        .then(() => {
          this.confirmIgnored = false
          this.markAssisted()
        })
        .catch((error) => (error))
        // .finally(() => (this.$router.go()))
    },
    reload () {
      this.confirm = !this.confirm
      this.$router.go()
    }
  }
}
</script>
<style scoped>
.cityTag {
  position: relative !important;
}
@media (max-width: 400px) {
  .custom-width {
    max-width: 105px !important;
  }
  .avatar_size {
    width: 60px !important;
    height: 60px !important;
  }
}

@media (max-width: 800px) {
  .custom-width {
    max-width: 40vw;
  }
}
</style>
